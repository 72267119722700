import {SET_ACCOUNT_MOVEMENT_EDIT_VOUCHER,SET_LIST_ACCOUNT_MOVEMENT_TO_SELECT_EDIT_VOUCHER,ADD_ROW_MOVEMENTS_TABLE_EDIT_VOUCHER,UPDATE_ROW_MOVEMENTS_TABLE_EDIT_VOUCHER,DELETE_ROW_MOVEMENTS_TABLE_EDIT_VOUCHER,SET_LIST_ACCOUNT_MOVEMENT_EDIT_VOUCHER,RESET_EDIT_VOUCHER} from '../../../../constants/ActionTypes'
import { currencyFormat} from '../../../../util/DaFrameword'
const InitialState={
    AccountMovement:{
        active: true,
        companyId: "",
        concept: "",
        date: "",
        documentEmissionId: 0,
        id: 0,
        length: 0,
        moduleInterfaceId: 0,
        number: 0
    },
    ListAccountMovementToSelect:[],
    MovementsTableData:[]
}

function EditVoucher (state=InitialState,action){
    switch (action.type) {
        case SET_ACCOUNT_MOVEMENT_EDIT_VOUCHER:
            return{
                ...state,
                AccountMovement:action.payload.data
            }
        case SET_LIST_ACCOUNT_MOVEMENT_TO_SELECT_EDIT_VOUCHER:
            return{
                ...state,
                ListAccountMovementToSelect:action.payload.data
            }
            case ADD_ROW_MOVEMENTS_TABLE_EDIT_VOUCHER:
                return {
                    ...state,
                    MovementsTableData:[...state.MovementsTableData,action.payload]
                }
            case UPDATE_ROW_MOVEMENTS_TABLE_EDIT_VOUCHER:
                //Busco la data actual
                let PrevData=state.MovementsTableData;
                //actualizo la posicion
                PrevData[action.payload.index]=action.payload.data;
                return {
                    ...state,
                    MovementsTableData:PrevData
                }
            case DELETE_ROW_MOVEMENTS_TABLE_EDIT_VOUCHER:
                let filteredMovements = state.MovementsTableData.filter((_,i) => i != action.payload);
                //D.splice(action.payload,1);
                //  console.log(state.MovementsTableData,action.payload,D,"ddddddddddddddddddddddddddddddd")
                return{
                    ...state,
                    MovementsTableData:filteredMovements
                }
            case SET_LIST_ACCOUNT_MOVEMENT_EDIT_VOUCHER:
                return {
                    ...state,
                    MovementsTableData: action.payload.TableMovement.map(x=>({
                                                        Account:x.account,
                                                        Display:x.display,
                                                        AuxiliaryType:x.auxiliaryType,
                                                        CatalogAccountId:x.catalogAccountId,
                                                        ListAuxiliaryMovements:x.listAuxiliaryMovements.map((z,i)=>({
                                                            key:i+1,
                                                            AuxiliaryCode:z.auxiliaryCode,
                                                            AuxiliaryId:z.auxiliaryId,
                                                            CostCenter:z.costCenter,
                                                            RegionId:z.regionId,
                                                            Value:z.value,
                                                            CostCenterDisplay:z.costCenterDisplay,
                                                            RegionDisplay:z.regionDisplay,
                                                            ValueDisplay:currencyFormat(z.value),
                                                            Display:z.display
                                                        })),
                                                        Origin:x.origin,
                                                        Value:x.value,
                                                        key:`${x.catalogAccountId}${x.origin}`
                                         }))
                }
            case RESET_EDIT_VOUCHER:
                return InitialState;
        default:
            return state;
        
    }
}

export default EditVoucher;