// Customizer const
export const TOGGLE_COLLAPSED_NAV = 'TOGGLE_COLLAPSE_MENU';
export const WINDOW_WIDTH = 'WINDOW-WIDTH';
export const SWITCH_LANGUAGE = 'SWITCH-LANGUAGE';

//Contact Module const
export const FETCH_START = 'fetch_start';
export const FETCH_SUCCESS = 'fetch_success';
export const FETCH_ERROR = 'fetch_error';
export const SHOW_MESSAGE = 'SHOW_MESSAGE';
export const HIDE_MESSAGE = 'HIDE_MESSAGE';
export const ON_SHOW_LOADER = 'ON_SHOW_LOADER';
export const ON_HIDE_LOADER = 'ON_HIDE_LOADER';
export const GET_DEVICE_IP='GET_DEVICE_IP';

//Auth const
export const SIGNUP_USER = 'SIGNUP_USER';
export const SIGNUP_USER_SUCCESS = 'SIGNUP_USER_SUCCESS';
export const SIGNIN_GOOGLE_USER = 'SIGNIN_GOOGLE_USER';
export const SIGNIN_GOOGLE_USER_SUCCESS = 'SIGNIN_GOOGLE_USER_SUCCESS';
export const SIGNIN_FACEBOOK_USER = 'SIGNIN_FACEBOOK_USER';
export const SIGNIN_FACEBOOK_USER_SUCCESS = 'SIGNIN_FACEBOOK_USER_SUCCESS';
export const SIGNIN_TWITTER_USER = 'SIGNIN_TWITTER_USER';
export const SIGNIN_TWITTER_USER_SUCCESS = 'SIGNIN_TWITTER_USER_SUCCESS';
export const SIGNIN_GITHUB_USER = 'SIGNIN_GITHUB_USER';
export const SIGNIN_GITHUB_USER_SUCCESS = 'signin_github_user_success';
export const SIGNIN_USER = 'SIGNIN_USER';
export const SIGNIN_USER_SUCCESS = 'SIGNIN_USER_SUCCESS';
export const SIGNOUT_USER = 'SIGNOUT_USER';
export const SIGNOUT_USER_SUCCESS = 'SIGNOUT_USER_SUCCESS';
export const INIT_URL = 'INIT_URL';

//Sticky
export const GET_STICKY = 'GET_STICKY';
export const NOTES_UPDATE='NOTES_UPDATE';
export const FETCH_ALL_NOTES_SUCCESS='FETCH_ALL_NOTES_SUCCESS';
export const UPDATE_ALL_NOTES_SUCCESS='UPDATE_ALL_NOTES_SUCCESS';

//Contact
export const GET_All_CONTACT_SUCCESS = 'GET_All_CONTACT_SUCCESS';
export const ON_ADD_CONTACT_SUCCESS = 'ON_ADD_CONTACT_SUCCESS';
export const UPDATE_CONTACT_SUCCESS='UPDATE_CONTACT_SUCCESS';
export const DELETE_CONTACT_SUCCESS='DELETE_CONTACT_SUCCESS';


//CompanySelector
export const SET_ALL_COMPANIES='SET_ALL_COMPANIES';

export const SET_ALL_BRANCHES='SET_ALL_BRANCHES';

export const SET_NAVBAR_ITEMS='SET_NAVBAR_ITEMS'

//FinancialStatement

export const SET_FINANCIAL_STATE_TYPE_VALUE="SET_FINANCIAL_STATE_TYPE_VALUE";


export const GET_FINANCIAL_STATEMENT_TYPE="GET_FINANCIAL_STATEMENT_TYPE";
export const GET_CONTABLE_GROUP="GET_CONTABLE_GROUP"
export const GET_CONTAB_TITLE="GET_CONTAB_TITLE"
export const GET_CONTAB_ANNEX="GET_CONTAB_ANNEX"
export const ADD_STATEMENT_ACCOUNT ="ADD_STATEMENT_ACCOUNT"
export const UPDATE_STATEMENT_ACCOUNT ="UPDATE_STATEMENT_ACCOUNT"

export const GET_STATE_ACCOUNT_SEARCH_DATA='GET_STATE_ACCOUNT_SEARCH_DATA'


export const GET_AUXILIARY_CLASSIFICATION_DATA="GET_AUXILIARY_CLASSIFICATION_DATA"

//DocumentAbbreviation
export const GET_ALL_DOCUMENT_ABBREVIATION_DATA="GET_ALL_DOCUMENT_ABBREVIATION_DATA"
export const SET_ADD_DOCUMENT_MODAL_OPEN="SET_ADD_DOCUMENT_MODAL_OPEN"
export const SET_ADD_DOCUMENT_MODAL_CLOSE="SET_ADD_DOCUMENT_MODAL_CLOSE"
export const SET_SEARCH_DOCUMENT="SET_SEARCH_DOCUMENT"
export const TOOGLE_DOCUMENT_ABBREVIATION="TOOGLE_DOCUMENT_ABBREVIATION";
export const FILTER_DOCUMENTS_ABBREVIATION="FILTER_DOCUMENTS_ABBREVIATION"
export const SELECT_FILTER_OPTION_DOCUMENT_EMISSION="SELECT_FILTER_OPTION_DOCUMENT_EMISSION"



export  const GET_CONTAB_ACTIVE_REFERENCES="GET_CONTAB_ACTIVE_REFERENCES";
export  const GET_CONTAB_ACTIVE_ACTION="GET_CONTAB_ACTIVE_ACTION";
export  const GET_DOCUMENT_INTERFACE="GET_DOCUMENT_INTERFACE";
export const GET_ALL_ACTION_PROGRAM_ACCOUNT_DATA="GET_ALL_ACTION_PROGRAM_ACCOUNT_DATA";
export const SET_ADD_ACTION_PROGRAM_ACCOUNT_MODAL_OPEN="SET_ADD_ACTION_PROGRAM_ACCOUNT_MODAL_OPEN"
export const SET_ADD_ACTION_PROGRAM_ACCOUNT_MODAL_CLOSE="SET_ADD_ACTION_PROGRAM_ACCOUNT_MODAL_CLOSE"
export const SET_SEARCH_APC="SET_SEARCH_APC"
export const FILTER_ACTION_PROGRAM_ACCOUNT="FILTER_ACTION_PROGRAM_ACCOUNT"
export const SELECT_FILTER_OPTION_ACTION_PROGRAM_ACCOUNT="SELECT_FILTER_OPTION_ACTION_PROGRAM_ACCOUNT"
export const TOOGLE_DRAWER_CONTAB_ACTION_PROGRAM_ACCOUNT="TOOGLE_DRAWER_CONTAB_ACTION_PROGRAM_ACCOUNT"
export const ADD_Actions_Assigned="ADD_Actions_Assigned"
export const UPDATE_Actions_Assigned="UPDATE_Actions_Assigned"
export const Reset_Actions_Assigned="Reset_Actions_Assigned"
export const ADD_ACTION_ACCOUNT="ACTION_ACCOUNT" 
export const RESET_ACTION_ACCOUNT="RESET_ACTION_ACCOUNT"
export const OPEN_CLOSE_ACTION_PROGRAM_ACCOUNT="OPEN_CLOSE_ACTION_PROGRAM_ACCOUNT"

//ContabAction
export const GET_ALL_CONTAB_ACTION="GET_ALL_CONTAB_ACTION"
export const SET_SEARCH_CONTAB_ACTION="SET_SEARCH_CONTAB_ACTION"
export const SELECT_FILTER_OPTION_CONTAB_ACTION="SELECT_FILTER_OPTION_CONTAB_ACTION"
export const FILTER_CONTAB_ACTION="FILTER_CONTAB_ACTION"
export const SET_ADD_CONTAB_ACTION_MODAL_OPEN="SET_ADD_CONTAB_ACTION_MODAL_OPEN"
export const SET_ADD_CONTAB_ACTION_MODAL_CLOSE="SET_ADD_CONTAB_ACTION_MODAL_CLOSE"
export const TOOGLE_DRAWER_CONTAB_ACTION="TOOGLE_DRAWER_CONTAB_ACTION"

//BankRegister
export const GET_ALL_BANK_REGISTER="GET_ALL_BANK_REGISTER"
export const SET_SEARCH_BANK_REGISTER="SET_SEARCH_BANK_REGISTER"
export const SELECT_FILTER_OPTION_BANK_REGISTER="SELECT_FILTER_OPTION_BANK_REGISTER"
export const FILTER_BANK_REGISTER="FILTER_BANK_REGISTER"
export const SET_ADD_BANK_REGISTER_MODAL_OPEN="SET_ADD_BANK_REGISTER_MODAL_OPEN"
export const SET_ADD_BANK_REGISTER_MODAL_CLOSE="SET_ADD_BANK_REGISTER_MODAL_CLOSE"
export const TOOGLE_DRAWER_BANK_REGISTER="TOOGLE_DRAWER_BANK_REGISTER"

//costCenter
export const GET_ALL_COST_CENTER="GET_ALL_COST_CENTER"
export const SET_SEARCH_COST_CENTER="SET_SEARCH_COST_CENTER"
export const SELECT_FILTER_OPTION_COST_CENTER="SELECT_FILTER_OPTION_COST_CENTER"
export const FILTER_COST_CENTER="FILTER_COST_CENTER"
export const SET_ADD_COST_CENTER_MODAL_OPEN="SET_ADD_COST_CENTER_MODAL_OPEN"
export const SET_ADD_COST_CENTER_MODAL_CLOSE="SET_ADD_COST_CENTER_MODAL_CLOSE"
export const TOOGLE_DRAWER_COST_CENTER="TOOGLE_DRAWER_COST_CENTER"

//ContabTitle
export const GET_ALL_CONTAB_TITLE="GET_ALL_CONTAB_TITLE"
export const SET_SEARCH_CONTAB_TITLE="SET_SEARCH_CONTAB_TITLE"
export const SELECT_FILTER_OPTION_CONTAB_TITLE="SELECT_FILTER_OPTION_CONTAB_TITLE"
export const FILTER_CONTAB_TITLE="FILTER_CONTAB_TITLE"
export const SET_ADD_CONTAB_TITLE_MODAL_OPEN="SET_ADD_CONTAB_TITLE_MODAL_OPEN"
export const SET_ADD_CONTAB_TITLE_MODAL_CLOSE="SET_ADD_CONTAB_TITLE_MODAL_CLOSE"
export const TOOGLE_DRAWER_CONTAB_TITLE="TOOGLE_DRAWER_CONTAB_TITLE"

//ContabAnnex
export const GET_ALL_CONTAB_ANNEX="GET_ALL_CONTAB_ANNEX"
export const SET_SEARCH_CONTAB_ANNEX="SET_SEARCH_CONTAB_ANNEX"
export const SELECT_FILTER_OPTION_CONTAB_ANNEX="SELECT_FILTER_OPTION_CONTAB_ANNEX"
export const FILTER_CONTAB_ANNEX="FILTER_CONTAB_ANNEX"
export const SET_ADD_CONTAB_ANNEX_MODAL_OPEN="SET_ADD_CONTAB_ANNEX_MODAL_OPEN"
export const SET_ADD_CONTAB_ANNEX_MODAL_CLOSE="SET_ADD_CONTAB_ANNEX_MODAL_CLOSE"
export const TOOGLE_DRAWER_CONTAB_ANNEX="TOOGLE_DRAWER_CONTAB_ANNEX"

//DebtCollector
export const GET_ALL_DEBT_COLLECTOR="GET_ALL_DEBT_COLLECTOR"
export const SET_SEARCH_DEBT_COLLECTOR="SET_SEARCH_DEBT_COLLECTOR"
export const SELECT_FILTER_OPTION_DEBT_COLLECTOR="SELECT_FILTER_OPTION_DEBT_COLLECTOR"
export const FILTER_DEBT_COLLECTOR="FILTER_DEBT_COLLECTOR"
export const SET_ADD_DEBT_COLLECTOR_MODAL_OPEN="SET_ADD_DEBT_COLLECTOR_MODAL_OPEN"
export const SET_ADD_DEBT_COLLECTOR_MODAL_CLOSE="SET_ADD_DEBT_COLLECTOR_MODAL_CLOSE"
export const TOOGLE_DRAWER_DEBT_COLLECTOR="TOOGLE_DRAWER_DEBT_COLLECTOR"

//Seller
export const GET_ALL_SELLER="GET_ALL_SELLER"
export const SET_SEARCH_SELLER="SET_SEARCH_SELLER"
export const SELECT_FILTER_OPTION_SELLER="SELECT_FILTER_OPTION_SELLER"
export const FILTER_SELLER="FILTER_SELLER"
export const SET_ADD_SELLER_MODAL_OPEN="SET_ADD_SELLER_MODAL_OPEN"
export const SET_ADD_SELLER_MODAL_CLOSE="SET_ADD_SELLER_MODAL_CLOSE"
export const TOOGLE_DRAWER_SELLER="TOOGLE_DRAWER_SELLER"

//AuxiliaryClassification
export const GET_ALL_AUXILIARY_CLASSIFICATION="GET_ALL_AUXILIARY_CLASSIFICATION"
export const SET_SEARCH_AUXILIARY_CLASSIFICATION="SET_SEARCH_AUXILIARY_CLASSIFICATION"
export const SELECT_FILTER_OPTION_AUXILIARY_CLASSIFICATION="SELECT_FILTER_OPTION_AUXILIARY_CLASSIFICATION"
export const FILTER_AUXILIARY_CLASSIFICATION="FILTER_AUXILIARY_CLASSIFICATION"
export const SET_ADD_AUXILIARY_CLASSIFICATION_MODAL_OPEN="SET_ADD_AUXILIARY_CLASSIFICATION_MODAL_OPEN"
export const SET_ADD_AUXILIARY_CLASSIFICATION_MODAL_CLOSE="SET_ADD_AUXILIARY_CLASSIFICATION_MODAL_CLOSE"
export const TOOGLE_DRAWER_AUXILIARY_CLASSIFICATION="TOOGLE_DRAWER_AUXILIARY_CLASSIFICATION"

//DynamicTax
export const GET_ALL_DYNAMIC_TAX="GET_ALL_DYNAMIC_TAX"
export const SET_SEARCH_DYNAMIC_TAX="SET_SEARCH_DYNAMIC_TAX"
export const SELECT_FILTER_OPTION_DYNAMIC_TAX="SELECT_FILTER_OPTION_DYNAMIC_TAX"
export const FILTER_DYNAMIC_TAX="FILTER_DYNAMIC_TAX"
export const SET_ADD_DYNAMIC_TAX_MODAL_OPEN="SET_ADD_DYNAMIC_TAX_MODAL_OPEN"
export const SET_ADD_DYNAMIC_TAX_MODAL_CLOSE="SET_ADD_DYNAMIC_TAX_MODAL_CLOSE"
export const TOOGLE_DRAWER_DYNAMIC_TAX="TOOGLE_DRAWER_DYNAMIC_TAX"

//BoxCatalogAccount
export const GET_ALL_BOX_CATALOG_ACCOUNT="GET_ALL_BOX_CATALOG_ACCOUNT"
export const SET_SEARCH_BOX_CATALOG_ACCOUNT="SET_SEARCH_BOX_CATALOG_ACCOUNT"
export const SELECT_FILTER_OPTION_BOX_CATALOG_ACCOUNT="SELECT_FILTER_OPTION_BOX_CATALOG_ACCOUNT"
export const FILTER_BOX_CATALOG_ACCOUNT="FILTER_BOX_CATALOG_ACCOUNT"
export const SET_ADD_BOX_CATALOG_ACCOUNT_MODAL_OPEN="SET_ADD_BOX_CATALOG_ACCOUNT_MODAL_OPEN"
export const SET_ADD_BOX_CATALOG_ACCOUNT_MODAL_CLOSE="SET_ADD_BOX_CATALOG_ACCOUNT_MODAL_CLOSE"
export const TOOGLE_DRAWER_BOX_CATALOG_ACCOUNT="TOOGLE_DRAWER_BOX_CATALOG_ACCOUNT"

//DATATYPE
// export const GET_ALL_DATATYPE="GET_ALL_DATATYPE"
// export const SET_SEARCH_DATATYPE="SET_SEARCH_DATATYPE"
// export const SELECT_FILTER_OPTION_DATATYPE="SELECT_FILTER_OPTION_DATATYPE"
// export const FILTER_DATATYPE="FILTER_DATATYPE"
// export const SET_ADD_DATATYPE_MODAL_OPEN="SET_ADD_DATATYPE_MODAL_OPEN"
// export const SET_ADD_DATATYPE_MODAL_CLOSE="SET_ADD_DATATYPE_MODAL_CLOSE"
// export const TOOGLE_DRAWER_DATATYPE="TOOGLE_DRAWER_DATATYPE"

export const GET_ALL_DOCUMENT_CONFIGURATION="GET_ALL_DOCUMENT_CONFIGURATION"

export const SET_AUXILIARY_CLASSIFICATION_ID="SET_AUXILIARY_CLASSIFICATION_ID"


export const SELECT_FILTER_OPTION_DOCUMENT_CONFIGURATION="SELECT_FILTER_OPTION_DOCUMENT_CONFIGURATION"
export const FILTER_DOCUMENT_CONFIGURATION="FILTER_DOCUMENT_CONFIGURATION"
export const SET_SEARCH_DOCUMENT_CONFIGURATION="SET_SEARCH_DOCUMENT_CONFIGURATION";


export const SET_VALIDATION_MESSAGE_VOUCHER_RECORD="SET_VALIDATION_MESSAGE_VOUCHER_RECORD"
export const TOOGLE_ALERT_VOUCHER_RECORD="TOOGLE_ALERT_VOUCHER_RECORD"
export const GET_SPECIFIC_DOCUMENT_DATA_VOUCHER_RECORD="GET_SPECIFIC_DOCUMENT_DATA_VOUCHER_RECORD"
export const ADD_ROW_MOVEMENTS_TABLE="ADD_ROW_MOVEMENTS_TABLE"
export const UPDATE_ROW_MOVEMENTS_TABLE="UPDATE_ROW_MOVEMENTS_TABLE"
export const TOOGLE_HEADER_VISIBILITY="TOOGLE_HEADER_VISIBILITY"
export const RESET_VOUCHER_RECORD="RESET_VOUCHER_RECORD"
export const SET_ALL_MOVEMENTS_CLOSING_DATE="SET_ALL_MOVEMENTS_CLOSING_DATE"


export const DELETE_ROW_MOVEMENTS_TABLE="DELETE_ROW_MOVEMENTS_TABLE"
export const FILTER_MOVEMENT_DATA_BY_REFERENCE="FILTER_MOVEMENT_DATA_BY_REFERENCE"
export const RESET_TABLE_MOVEMENTS_VOUCHER_RECORD="RESET_TABLE_MOVEMENTS_VOUCHER_RECORD"

export const SET_ACCOUNT_MOVEMENT_EDIT_VOUCHER="SET_ACCOUNT_MOVEMENT_EDIT_VOUCHER"

export const SET_LIST_ACCOUNT_MOVEMENT_TO_SELECT_EDIT_VOUCHER="SET_LIST_ACCOUNT_MOVEMENT_TO_SELECT_EDIT_VOUCHER"
export const ADD_ROW_MOVEMENTS_TABLE_EDIT_VOUCHER="ADD_ROW_MOVEMENTS_TABLE_EDIT_VOUCHER"
export const UPDATE_ROW_MOVEMENTS_TABLE_EDIT_VOUCHER="UPDATE_ROW_MOVEMENTS_TABLE_EDIT_VOUCHER"
export const DELETE_ROW_MOVEMENTS_TABLE_EDIT_VOUCHER="DELETE_ROW_MOVEMENTS_TABLE_EDIT_VOUCHER"
export const SET_LIST_ACCOUNT_MOVEMENT_EDIT_VOUCHER="SET_LIST_ACCOUNT_MOVEMENT_EDIT_VOUCHER";
export const RESET_EDIT_VOUCHER="RESET_EDIT_VOUCHER"
export const GET_LAST_CONTABLE_CLOSING_DATE="GET_LAST_CONTABLE_CLOSING_DATE"
export const GET_LAST_Depreciation_CLOSING_DATE="GET_LAST_Depreciation_CLOSING_DATE"
export const CONSULT_ACCOUNT_MOVEMENT="CONSULT_ACCOUNT_MOVEMENT"
export const SET_CATALOG_ACCOUNT_CONSULT_ACCOUNT="SET_CATALOG_ACCOUNT_CONSULT_ACCOUNT"
export const RESET_CONSULT_ACCOUNT="RESET_CONSULT_ACCOUNT"


export const ADD_ACCOUNT_MOVEMENT_VOUCHER_RECORD="ADD_ACCOUNT_MOVEMENT_VOUCHER_RECORD"
export const ADD_ACCOUNT_MOVEMENT_Depreciation_RECORD="ADD_ACCOUNT_MOVEMENT_Depreciation_RECORD"

export const GET_TABLE_DATA_TAXPAYER_SEQUENCE_REQUEST_REGISTER="GET_TABLE_DATA_TAXPAYER_SEQUENCE_REQUEST_REGISTER"
export const SET_FORM_DATA_TAXPAYER_SEQUENCE_REQUEST_REGISTER="SET_FORM_DATA_TAXPAYER_SEQUENCE_REQUEST_REGISTER"
export const SET_DISABLED_HEADER_TAXPAYER_SEQUENCE_REQUEST_REGISTER="SET_DISABLED_HEADER_TAXPAYER_SEQUENCE_REQUEST_REGISTER"
export const UPDATE_AUTHORIZE_NUMBER_SEQUENCE_REQUEST_REGISTER="UPDATE_AUTHORIZE_NUMBER_SEQUENCE_REQUEST_REGISTER"
export const RESET_STATE_TAXPAYER_SEQUENCE_REQUEST_REGISTER="RESET_STATE_TAXPAYER_SEQUENCE_REQUEST_REGISTER"

export const ADD_PRODUCT_IN_PRODUCT_REGISTER="ADD_PRODUCT_IN_PRODUCT_REGISTER"
export const SET_FORM_FIELD_IN_PRODUCTO_REGISTER="SET_FORM_FIELD_IN_PRODUCTO_REGISTER"
export const GET_PRODUCT_LIST_PRODUCT_REGISTER="GET_PRODUCT_LIST_PRODUCT_REGISTER"

export const Get_AccountingDepartment_Register="Get_AccountingDepartment_Register"

//Products 
export const ADD_Local_ROW_MOVEMENTS_TABLE="ADD_Local_ROW_MOVEMENTS_TABLE"
export const UPDATE_Local_ROW_MOVEMENTS_TABLE="UPDATE_Local_ROW_MOVEMENTS_TABLE"
export const GET_INVENTORY_LIST_PRODUCT_REGISTER="GET_INVENTORY_LIST_PRODUCT_REGISTER"
export const Reset_Local_ROW_MOVEMENTS_TABLE="Reset_Local_ROW_MOVEMENTS_TABLE"
export const DELETE_LOCALPURCHASE_DETAIL_LOCALPURCHASE_REGISTER="DELETE_LOCALPURCHASE_DETAIL_LOCALPURCHASE_REGISTER"

export const Add_LocalPurchase_ROW_TABLE="Add_LocalPurchase_ROW_TABLE"

export const RESET_LocalPurchase_ROW_TABLE="RESET_LocalPurchase_ROW_TABLE"
//Invoice
export const ADD_Invoice_ROW_MOVEMENTS_TABLE="ADD_Invoice_ROW_MOVEMENTS_TABLE"
export const UPDATE_Invoice_ROW_MOVEMENTS_TABLE="UPDATE_Invoice_ROW_MOVEMENTS_TABLE"
export const Reset_Invoice_ROW_MOVEMENTS_TABLE="Reset_Invoice_ROW_MOVEMENTS_TABLE"
export const GET_PendingInvoice_LIST_Register="GET_PendingInvoice_LIST_Register"
export const DELETE_INVOICE_DETAIL_iNVOICE_REGISTER_POS="DELETE_INVOICE_DETAIL_iNVOICE_REGISTER_POS"
export const ADD_PROCESS_INVOICE_ID ="ADD_PROCESS_INVOICE_ID"
export const ADD_PROCESS_INVOICE_ID_FOR_SALES="ADD_PROCESS_INVOICE_ID_FOR_SALES"
export const DELETE_PROCESS_INVOICE_ID="DELETE_PROCESS_INVOICE_ID"
export const DELETE_PROCESS_INVOICE_FOR_SALES_ID="DELETE_PROCESS_INVOICE_FOR_SALES_ID"
export const Add_Invoice_ROW_TABLE="Add_Invoice_ROW_TABLE"
export const RESET_Invoice_ROW_TABLE="RESET_Invoice_ROW_TABLE"

//InvetoryMovement
export const ADD_Inventory_ROW_MOVEMENTS_TABLE="ADD_Inventory_ROW_MOVEMENTS_TABLE"
export const UPDATE_Inventory_ROW_MOVEMENTS_TABLE="UPDATE_Inventory_ROW_MOVEMENTS_TABLE"
export const Reset_Inventory_ROW_MOVEMENTS_TABLE= "Reset_Inventory_ROW_MOVEMENTS_TABLE"


export const ADD_INVOICE_DETAIL_INVOICE_REGISTER="ADD_INVOICE_DETAIL_INVOICE_REGISTER"
export const UPDATE_INVOICE_DETAIL_INVOICE_REGISTER="UPDATE_INVOICE_DETAIL_INVOICE_REGISTER"
export const SET_ITBIS_TAX_INVOICE_REGISTER="SET_ITBIS_TAX_INVOICE_REGISTER"
export const DELETE_INVOICE_DETAIL_INVOICE_REGISTER="DELETE_INVOICE_DETAIL_INVOICE_REGISTER"
export const RESER_INVOICE_REGISTER="RESER_INVOICE_REGISTER"

export const GET_TABLE_DATA_NCF_ACCOUNT="GET_TABLE_DATA_NCF_ACCOUNT"

export const ADD_INVOICE_DETAIL_BUY_GOODS_AND_SERVICES="ADD_INVOICE_DETAIL_BUY_GOODS_AND_SERVICES"
export const UPDATE_INVOICE_DETAIL_BUY_GOODS_AND_SERVICES="UPDATE_INVOICE_DETAIL_BUY_GOODS_AND_SERVICES"
export const SET_ITBIS_TAX_BUY_GOODS_AND_SERVICES="SET_ITBIS_TAX_BUY_GOODS_AND_SERVICES"
export const DELETE_INVOICE_DETAIL_BUY_GOODS_AND_SERVICES="DELETE_INVOICE_DETAIL_BUY_GOODS_AND_SERVICES"
export const RESET_BUY_GOODS_AND_SERVICES="RESET_BUY_GOODS_AND_SERVICES"
export const SET_DYNAMIC_TAX_INPUT_BUY_GOODS_AND_SERVICES="SET_DYNAMIC_TAX_INPUT_BUY_GOODS_AND_SERVICES"
export const SET_DYNAMIC_INPUTS_VALUE_BUY_GOODS_AND_SERVICES="SET_DYNAMIC_INPUTS_VALUE_BUY_GOODS_AND_SERVICES"


export const ADD_INVOICE_DETAIL_PROOF_OF_PURCHASE="ADD_INVOICE_DETAIL_PROOF_OF_PURCHASE"
export const UPDATE_INVOICE_DETAIL_PROOF_OF_PURCHASE="UPDATE_INVOICE_DETAIL_PROOF_OF_PURCHASE"
export const SET_ITBIS_TAX_PROOF_OF_PURCHASE="SET_ITBIS_TAX_PROOF_OF_PURCHASE"
export const DELETE_INVOICE_DETAIL_PROOF_OF_PURCHASE="DELETE_INVOICE_DETAIL_PROOF_OF_PURCHASE"
export const RESET_PROOF_OF_PURCHASE="RESET_PROOF_OF_PURCHASE"
export const SET_DYNAMIC_TAX_INPUT_PROOF_OF_PURCHASE="SET_DYNAMIC_TAX_INPUT_PROOF_OF_PURCHASE"
export const SET_DYNAMIC_INPUTS_VALUE_PROOF_OF_PURCHASE="SET_DYNAMIC_INPUTS_VALUE_PROOF_OF_PURCHASE"
export const SET_ISR_PERCENT_PROOF_OF_PURCHASE="SET_ISR_PERCENT_PROOF_OF_PURCHASE"

export const GET_TABLE_DATA_ACCOUNT_BANK="GET_TABLE_DATA_ACCOUNT_BANK"

export const DISABLE_HEADER_INCOME_RECEIPT="DISABLE_HEADER_INCOME_RECEIPT";
export const SET_PENDING_MOVEMENT_INCOME_RECEIPT="SET_PENDING_MOVEMENT_INCOME_RECEIPT"
export const SET_VALUE_TO_DISTRIBUTE_INCOME_RECEIPT="SET_VALUE_TO_DISTRIBUTE_INCOME_RECEIPT"
export const UPDATE_TABLE_DATA_INCOME_RECEIPT="UPDATE_TABLE_DATA_INCOME_RECEIPT";
export const UPDATE_VALUE_TO_DISTRIBUTE_INCOME_RECEIPT="UPDATE_VALUE_TO_DISTRIBUTE_INCOME_RECEIPT"
export const RESET_INCOME_RECEIPT="RESET_INCOME_RECEIPT"
export const PENDING_BALANCE_INCOME_RECEIPT="PENDING_BALANCE_INCOME_RECEIPT"
export const SET_RETAINED_PERCENT_AND_STATUS_INCOME_RECEIPT= "SET_RETAINED_PERCENT_AND_STATUS_INCOME_RECEIPT"
export const Reset_RETAINED_PERCENT_AND_STATUS_INCOME_RECEIPT="Reset_RETAINED_PERCENT_AND_STATUS_INCOME_RECEIPT"
export const SET_RETAINED_PERCENT_AND_STATUS_OUTCOME_PAYMENT_CXC="SET_RETAINED_PERCENT_AND_STATUS_OUTCOME_PAYMENT_CXC"


export const GET_CUSTOMER_MOVEMENT_CUSTOMER_MOVEMENT="GET_CUSTOMER_MOVEMENT_CUSTOMER_MOVEMENT"
export const GET_BALANCE_AGE_CUSTOMER_MOVEMENT="GET_BALANCE_AGE_CUSTOMER_MOVEMENT"
export const RESET_CUSTOMER_MOVEMENT="RESET_CUSTOMER_MOVEMENT";

export const GET_PROVIDER_MOVEMENT_PROVIDER_MOVEMENT="GET_CUSTOMER_MOVEMENT_PROVIDER_MOVEMENT"
export const GET_BALANCE_AGE_PROVIDER_MOVEMENT="GET_BALANCE_AGE_PROVIDER_MOVEMENT"
export const RESET_PROVIDER_MOVEMENT="RESET_PROVIDER_MOVEMENT";

export const SET_DATE_NCF_AVAILABLE="SET_DATE_NCF_AVAILABLE";
export const GET_NCF_AVAILABLE_DATA="GET_NCF_AVAILABLE_DATA";
export const RESET_NCF_AVAILABLE="RESET_NCF_AVAILABLE"

 export const DISABLE_HEADER_PROVIDER_PAYMENT="DISABLE_HEADER_PROVIDER_PAYMENT";
 export const SET_PENDING_MOVEMENT_PROVIDER_PAYMENT="SET_PENDING_MOVEMENT_PROVIDER_PAYMENT"
 export const SET_VALUE_TO_DISTRIBUTE_PROVIDER_PAYMENT="SET_VALUE_TO_DISTRIBUTE_PROVIDER_PAYMENT"
 export const UPDATE_TABLE_DATA_PROVIDER_PAYMENT="UPDATE_TABLE_DATA_PROVIDER_PAYMENT";
 export const UPDATE_VALUE_TO_DISTRIBUTE_PROVIDER_PAYMENT="UPDATE_VALUE_TO_DISTRIBUTE_PROVIDER_PAYMENT"
 export const RESET_PROVIDER_PAYMENT="RESET_PROVIDER_PAYMENT"
 export const PENDING_BALANCE_PROVIDER_PAYMENT="PENDING_BALANCE_PROVIDER_PAYMENT"
 export const SET_RETAINED_PERCENT_AND_STATUS_OUTCOME_PAYMENT="SET_RETAINED_PERCENT_AND_STATUS_OUTCOME_PAYMENT"

 export const GET_COMPANY_GROUPS_BY_USER="GET_COMPANY_GROUPS_BY_USER"

 export const SET_USER_COMPANY_CREATE="SET_USER_COMPANY_CREATE"

export const GET_COMPANY_GROUP_COMPANY_CREATE="GET_COMPANY_GROUP_COMPANY_CREATE"
export const UPDATE_COMPANY_GROUP_COMPANY_CREATE="UPDATE_COMPANY_GROUP_COMPANY_CREATE"
export const ADD_COMPANY_GROUP_COMPANY_CREATE="ADD_COMPANY_GROUP_COMPANY_CREATE"

export const GET_Branches_LIST_Register="GET_Branches_LIST_Register"

export const GET_ALL_COMPANY_BY_USER_COMPANY_CREATE="GET_ALL_COMPANY_BY_USER_COMPANY_CREATE"
export const ADD_UPDATE_COMPANY_COMPANY_CREATE="ADD_UPDATE_COMPANY_COMPANY_CREATE"

export const GET_ALL_BRANCH_BY_USER_COMPANY_CREATE="GET_ALL_BRANCH_BY_USER_COMPANY_CREATE"
export const ADD_UPDATE_BRANCH_COMPANY_CREATE="ADD_UPDATE_BRANCH_COMPANY_CREATE"

export const GET_ALL_USER_BY_USER_COMPANY_CREATE="GET_ALL_USER_BY_USER_COMPANY_CREATE"

export const GET_SPECIFIC_USER_BRANCH_COMPANY_CREATE="GET_SPECIFIC_USER_BRANCH_COMPANY_CREATE"
export const RESET_COMPANY_CREATE="RESET_COMPANY_CREATE"

export const SET_ENCRIPTION_COMPANY_GROUP_SELECTED="SET_ENCRIPTION_COMPANY_GROUP_SELECTED"
export const SET_ENCRIPTION_COMPANY_SELECTED="SET_ENCRIPTION_COMPANY_SELECTED"
export const SET_ENCRIPTION_COMPANY_BRANCH_SELECTED="SET_ENCRIPTION_COMPANY_BRANCH_SELECTED"
export const SET_ENCRIPTION_USER_SELECTED="SET_ENCRIPTION_USER_SELECTED"
export const KEY_PASSWORD="KEY_PASSWORD_TO_ENCRYPTION_DASI_DASISTEMAS_DBIKAA"
export const SET_USER_INTERFACE="SET_USER_INTERFACE"
export const VALIDATE_SESION_TIME_OUT="VALIDATE_SESION_TIME_OUT"

export const AXIOS_FETCH_START = 'AXIOS_FETCH_START';
export const AXIOS_FETCH_SUCCESS = 'AXIOS_FETCH_SUCCESS';
export const AXIOS_FETCH_ERROR = 'AXIOS_FETCH_ERROR';

export const GET_NEXT_CONTABLE_CLOSING_DATE="GET_NEXT_CONTABLE_CLOSING_DATE"
export const GET_NEXT_Depreciation_CLOSING_DATE="GET_NEXT_Depreciation_CLOSING_DATE"
export const GET_SUMMARY_CHECK_BALANCE="GET_SUMMARY_CHECK_BALANCE"
export const GET_SUMMARY_CHECK_BALANCE_ANNUAL="GET_SUMMARY_CHECK_BALANCE_ANNUAL"
export const GET_SUMMARYDEPRECIATION_CHECK_BALANCE="GET_SUMMARYDEPRECIATION_CHECK_BALANCE"

export const GET_ALL_MODULE_CLOSING="GET_ALL_MODULE_CLOSING"
export const UPDATE_MONTH_MODULE_CLOSING="UPDATE_MONTH_MODULE_CLOSING"
export const CALCULATE_QUANTITY_DEAL="CALCULATE_QUANTITY_DEAL"
export const RESET_ROW_BUDGET_REGISTER="RESET_ROW_BUDGET_REGISTER"
export const UPDATE_ROW_BUDGET_REGISTER="UPDATE_ROW_BUDGET_REGISTER"


export const ADD_INVOICE_DETAIL_BUGGET_REGISTER="ADD_INVOICE_DETAIL_BUGGET_REGISTER"
export const UPDATE_INVOICE_DETAIL_BUGGET_REGISTER="UPDATE_INVOICE_DETAIL_BUGGET_REGISTER"
export const SET_ITBIS_TAX_INVOICE_BUGGET="SET_ITBIS_TAX_INVOICE_BUGGET"
export const DELETE_INVOICE_DETAIL_BUGGET_REGISTER="DELETE_INVOICE_DETAIL_BUGGET_REGISTER"
export const RESER_BUGGET_REGISTER="RESER_BUGGET_REGISTER"


export const ADD_INVOICE_DETAIL_MEMBERSHIP_REGISTER="ADD_INVOICE_DETAIL_MEMBERSHIP_REGISTER"
export const UPDATE_INVOICE_DETAIL_MEMBERSHIP_REGISTER="UPDATE_INVOICE_DETAIL_MEMBERSHIP_REGISTER"
export const SET_ITBIS_TAX_INVOICE_MEMBERSHIP="SET_ITBIS_TAX_INVOICE_MEMBERSHIP"
export const DELETE_INVOICE_DETAIL_MEMBERSHIP_REGISTER="DELETE_INVOICE_DETAIL_MEMBERSHIP_REGISTER"
export const RESET_MEMBERSHIP_REGISTER="RESER_MEMBERSHIP_REGISTER"
export const SET_MEMBERSHIP_OBJECT="SET_MEMBERSHIP_OBJECT"


export const GET_DATA_TABLE_MEMBERSHIP_BILLING="GET_DATA_TABLE_MEMBERSHIP_BILLING"
export const UPDATE_ROW_SELECTION_MEMBERSHIP_BILLING="UPDATE_ROW_SELECTION_MEMBERSHIP_BILLING"
export const GET_AVAILABLE_MEMBERSHIP_BILLING="GET_AVAILABLE_MEMBERSHIP_BILLING"
export const RESET_MEMBERSHIP_BILLING="RESET_MEMBERSHIP_BILLING"
export const UPDATE_ALL_ROW_SELECTION_MEMBERSHIP_BILLING="UPDATE_ALL_ROW_SELECTION_MEMBERSHIP_BILLING"
export const SHOW_UPDATE_ROW_SELECTION_MEMBERSHIP_BILLING="SHOW_UPDATE_ROW_SELECTION_MEMBERSHIP_BILLING"
export const CALCULATE_CURRENCY_TAX_MEMBERSHIP_BILLING="CALCULATE_CURRENCY_TAX_MEMBERSHIP_BILLING"


export const GET_INVOICE_TO_PRINT_PRINT_INVOICE ="GET_INVOICE_TO_PRINT_PRINT_INVOICE"
export const UPDATE_ROW_SELECTION_PRINT_INVOICE="UPDATE_ROW_SELECTION_PRINT_INVOICE"
export const UPDATE_ALL_ROW_SELECTION_PRINT_INVOICE="UPDATE_ALL_ROW_SELECTION_PRINT_INVOICE"
export const GET_BANK_TRANSACTIONS_BANK_CONCILIATION="GET_BANK_TRANSACTIONS_BANK_CONCILIATION"
export const TOOGLE_TRANSACTION_BANK_CONCILIATION="TOOGLE_TRANSACTION_BANK_CONCILIATION"
export const SET_BANK_CONCILIATION_ID_BANK_CONCILIATION="SET_BANK_CONCILIATION_ID_BCREDIT_NOTICEANK_CONCILIATION"
export const CALCULATE_RESUMEN_BANK_CONCILIATION="CALCULATE_RESUMEN_BANK_CONCILIATION"
export const SET_BANK_ACCOUNT_ORIGIN_BANK_CONCILIATION="SET_BANK_ACCOUNT_ORIGIN_BANK_CONCILIATION"
export const SET_LAST_BALANCE_BANK_CONCILIATION="SET_LAST_BALANCE_BANK_CONCILIATION"
export const SET_BANK_BALANCE_BANK_CONCILIATION="SET_BANK_BALANCE_BANK_CONCILIATION"
export const RESET_BANK_CONCILIATION="RESET_BANK_CONCILIATION"
export const SELECT_ALL_BANK_CONCILIATION="SELECT_ALL_BANK_CONCILIATION"


export const DISABLE_HEADER_CREDIT_NOTICE="DISABLE_HEADER_CREDIT_NOTICE";
export const SET_PENDING_MOVEMENT_CREDIT_NOTICE="SET_PENDING_MOVEMENT_CREDIT_NOTICE"
export const SET_VALUE_TO_DISTRIBUTE_CREDIT_NOTICE="SET_VALUE_TO_DISTRIBUTE_CREDIT_NOTICE"
export const UPDATE_TABLE_DATA_CREDIT_NOTICE="UPDATE_TABLE_DATA_CREDIT_NOTICE";
export const UPDATE_VALUE_TO_DISTRIBUTE_CREDIT_NOTICE="UPDATE_VALUE_TO_DISTRIBUTE_CREDIT_NOTICE"
export const RESET_CREDIT_NOTICE="RESET_CREDIT_NOTICE"
export const PENDING_BALANCE_CREDIT_NOTICE="PENDING_BALANCE_CREDIT_NOTICE"


//CURRENCYTAX
export const GET_ALL_CURRENCYTAX="GET_ALL_CURRENCYTAX"
export const SET_SEARCH_CURRENCYTAX="SET_SEARCH_CURRENCYTAX"
export const SELECT_FILTER_OPTION_CURRENCYTAX="SELECT_FILTER_OPTION_CURRENCYTAX"
export const FILTER_CURRENCYTAX="FILTER_CURRENCYTAX"
export const SET_ADD_CURRENCYTAX_MODAL_OPEN="SET_ADD_CURRENCYTAX_MODAL_OPEN"
export const SET_ADD_CURRENCYTAX_MODAL_CLOSE="SET_ADD_CURRENCYTAX_MODAL_CLOSE"
export const TOOGLE_DRAWER_CURRENCYTAX="TOOGLE_DRAWER_CURRENCYTAX"
export const ADD_ROW_DETAIL_REPLENISHMENT_EXPENSES ="ADD_ROW_DETAIL_REPLENISHMENT_EXPENSES"
export const SET_BILL_DATA_CREDIT_NOTES ="SET_BILL_DATA_CREDIT_NOTES"
export const DISABLED_TABLE_EDIT_CREDIT_NOTES  ="DISABLED_TABLE_EDIT_CREDIT_NOTES"
export const GET_ALL_REPLENISHMENT_EXPENSES ="GET_ALL_REPLENISHMENT_EXPENSES"
export const SELECT_ROW_REPLENISHMENT_EXPENSES ="SELECT_ROW_REPLENISHMENT_EXPENSES"
export const SET_MAX_VALUE_DATA_CREDIT_NOTES ="SET_MAX_VALUE_DATA_CREDIT_NOTES"
export const FILTER_DATA_BY_ORIGIN_REPLENISHMENT_EXPENSES ="FILTER_DATA_BY_ORIGIN_REPLENISHMENT_EXPENSES"
export const FILTER_DATA_BY_TYPE_REPLENISHMENT_EXPENSES ="FILTER_DATA_BY_TYPE_REPLENISHMENT_EXPENSES"
export const SET_DAY_DIFF_CREDIT_NOTES="SET_DAY_DIFF_CREDIT_NOTES"

//PricingModel
export const GET_ALL_PricingModel="GET_ALL_PricingModel"
export const SET_ADD_PricingModel_MODAL_OPEN="SET_ADD_PricingModel_MODAL_OPEN"
export const SET_ADD_PricingModel_MODAL_CLOSE="SET_ADD_PricingModel_MODAL_CLOSE"
export const TOOGLE_DRAWER_Pricingmodel="TOOGLE_DRAWER_Pricingmodel"

//SACTIVE
export const ADD_MACHINETYPE="ADD_MACHINETYPE"
export const SET_FORM_FIELD_IN_MachineType="SET_FORM_FIELD_IN_MACHINETYPE"
export const GET_MACHINE_LIST_Register="GET_MACHINE_LIST_Register"

export const ADD_BRAND="ADD_BRAND"
export const SET_FORM_FIELD_IN_Brand="SET_FORM_FIELD_IN_Brand"
export const SET_Brand_Data="SET_Brand_Data"
export const GET_BRAND_LIST_Register="GET_BRAND_LIST_Register"

export const ADD_Model="ADD_Model"
export const SET_FORM_FIELD_IN_Model="SET_FORM_FIELD_IN_Model"
export const GET_Model_LIST_Register="GET_Model_LIST_Register"

export const ADD_Color="ADD_Color"
export const SET_FORM_FIELD_IN_Color="SET_FORM_FIELD_IN_Color"
export const GET_Color_LIST_Register="GET_Color_LIST_Register"

export const ADD_FuelType="ADD_FuelType"
export const SET_FORM_FIELD_IN_FuelType="SET_FORM_FIELD_IN_FuelType"
export const GET_FuelType_LIST_Register="GET_FuelType_LIST_Register"

export const ADD_Department="ADD_Department"
export const SET_FORM_FIELD_IN_Department="SET_FORM_FIELD_IN_Department"
export const GET_Department_LIST_Register="GET_Department_LIST_Register"

export const ADD_Clasification="ADD_Clasification"
export const SET_FORM_FIELD_IN_Clasification="SET_FORM_FIELD_IN_Clasification"
export const GET_Clasification_LIST_Register="GET_Clasification_LIST_Register"

export const ADD_AssetCategory="ADD_Clasification"
export const SET_FORM_FIELD_IN_AssetCategory="SET_FORM_FIELD_IN_AssetCategory"
export const GET_AssetCategory_LIST_Register="GET_AssetCategory_LIST_Register"


export const ADD_AdquisitionType="ADD_AdquisitionType"
export const SET_FORM_FIELD_IN_AdquisitionType="SET_FORM_FIELD_IN_AdquisitionType"
export const GET_AdquisitionType_LIST_Register = "GET_AdquisitionType_LIST_Register"

export const ADD_Assets="ADD_Assets"
export const SET_FORM_FIELD_IN_Assets="SET_FORM_FIELD_IN_Assets"
export const GET_Assets_LIST_Register="GET_Assets_LIST_Register"

export const SET_Table_Data="SET_Table_Data"
export const RESET_ASSETMOVEMENTTABLE="RESET_ASSETMOVEMENTTABLE"

export const SET_Table_ItbisRetentionCertification="SET_Table_ItbisRetentionCertification"
export const RESET_ItbisRetentionCertification_Table="RESET_ItbisRetentionCertification_Table"
export const Update_Table_ItbisRetentionCertification="Update_Table_ItbisRetentionCertification"

export const SET_Table_Data_ChargesBank="SET_Table_Data_ChargesBank"
export const RESET_Table_Data_ChargesBank="RESET_Table_Data_ChargesBank"

export const GET_TABLE_DATA_CHARGES_TYPE_ACCOUNT="GET_TABLE_DATA_CHARGES_TYPE_ACCOUNT"

export const GET_TABLE_DATA_ITBIS_RETAINED="GET_TABLE_DATA_ITBIS_RETAINED"

export const GET_TABLE_DATA_ISR_RETAINED="GET_TABLE_DATA_ISR_RETAINED"

export const GET_ManagerRegister_LIST="GET_ManagerRegister_LIST"

export const GET_Manufacturer_LIST_Register= "GET_Manufacturer_LIST_Register"

export const GET_LocationSection_LIST_Register="GET_LocationSection_LIST_Register"

export const GET_ProductClassification_LIST_Register="GET_ProductClassification_LIST_Register"

export const GET_ProductSubClassification_LIST_Register="GET_ProductSubClassification_LIST_Register"

export const GET_TrayLocation_LIST_Register="GET_TrayLocation_LIST_Register"

export const GET_MOVEMENT_BY_ITEM_LIST="GET_MOVEMENT_BY_ITEM_LIST"

export const RESET_INVENTORYMOVEMENTTABLE="RESET_INVENTORYMOVEMENTTABLE"

export const RESET_INVENTORY_MOVEMENT_REVERSE_ROW_TABLE="RESET_INVENTORY_MOVEMENT_REVERSE_ROW_TABLE"

export const Add_INVENTORY_MOVEMENT_REVERSE_ROW_TABLE="Add_INVENTORY_MOVEMENT_REVERSE_ROW_TABLE"
