import {Add_Invoice_ROW_TABLE,RESET_Invoice_ROW_TABLE} from '../../../../constants/ActionTypes'
import {zeroPad,currencyFormat} from '../../../../util/DaFrameword'
import moment from 'moment'

const InitialValue={
   InvoiceCopyTable:[]
}


function InvoiceCopy(state=InitialValue,action){

   switch (action.type) {
       case Add_Invoice_ROW_TABLE:     
          console.log(action,"valeus")       
           return {
               ...state,
               InvoiceCopyTable:action.payload.data.map(x=>({

                  productCode:zeroPad(x.code,8),
                  description:x.description,
                  
                  amount: x.quantity,

                  price: x.price,
    
                  value: x.value,
   
                  discount: x.discount,
  
                  ITBIS: x.itbisValue,

                  netValue: x.total,
               })) 
           }
       case RESET_Invoice_ROW_TABLE:
           return{
               ...state,
               InvoiceCopyTable:[]
           }
       default:
          return state
   }  

}

export default InvoiceCopy;