import React from 'react'
import {Switch,Route} from 'react-router-dom'
import asyncComponent from "../../../util/asyncComponent";
const ClosingAndOthersPointOfSale =({match})=>(
    <Switch>
    
         <Route  path={`${match.url}/ReverseReturnsInvoice`} component={asyncComponent(()=>import( './ReverseReturnsInvoice/index'))} />
          <Route  path={`${match.url}/EntryAndExitItemReverse`} component={asyncComponent(()=>import( './EntryAndExitItemReverse/index'))} />
          <Route  path={`${match.url}/LocalPurchaseReverse`} component={asyncComponent(()=>import( './LocalPurchaseReverse/index'))} />

    </Switch>
)
export default ClosingAndOthersPointOfSale;