import { Add_LocalPurchase_ROW_TABLE,RESET_LocalPurchase_ROW_TABLE} from '../../../../constants/ActionTypes'
import {zeroPad,currencyFormat} from '../../../../util/DaFrameword'

const InitialValue={
    LocalPurchaseList:[]
}
function ReverseLocalPurchase(state=InitialValue,action){

    switch (action.type) {
        case Add_LocalPurchase_ROW_TABLE:
            return {
                ...state,
                LocalPurchaseList:action.payload.data.map(x=>({
                    id:x.id,
                    description:x.description,
                    amount:x.amount,
                    price:currencyFormat(x.price),
                    value:currencyFormat(x.value),
                    discount: currencyFormat(x.discount),
                    ITBIS:x.isHasITBIS,
                    itbisValue: currencyFormat(x.itbisValue),
                    netWorth:currencyFormat(x.netWorth),
                })) 
            }
         case RESET_LocalPurchase_ROW_TABLE:
            return {
               LocalPurchaseList:[]
            }
        default:
           return state
    }  

}

export default ReverseLocalPurchase;