import {ADD_Invoice_ROW_MOVEMENTS_TABLE,UPDATE_Invoice_ROW_MOVEMENTS_TABLE,Reset_Invoice_ROW_MOVEMENTS_TABLE,GET_PendingInvoice_LIST_Register,ADD_PROCESS_INVOICE_ID,DELETE_INVOICE_DETAIL_iNVOICE_REGISTER_POS,DELETE_PROCESS_INVOICE_ID,DELETE_PROCESS_INVOICE_FOR_SALES_ID,ADD_PROCESS_INVOICE_ID_FOR_SALES} from '../../../../constants/ActionTypes'
import { zeroPad } from '../../../../util/DaFrameword';
import moment from 'moment';
const InitialState = {
    //InvoiceTableData:[],
    InvoiceHeader:[],
    PendingInvoiceList:[],
    ProcessInvoiceIds:[],
    ProcessInvoiceIdsForSales:[]
}

function Invoice(state = InitialState, action) {
    switch (action.type) {
        case ADD_Invoice_ROW_MOVEMENTS_TABLE:
        return{
            ...state,
            InvoiceHeader:[...state.InvoiceHeader,action.payload]          
        }

        case UPDATE_Invoice_ROW_MOVEMENTS_TABLE:
            let NewData=state.InvoiceHeader.map((data, index)=>(data.productId != action.payload.index ?  data : ({
                ...data,
                ...action.payload.data
              })));
              return {
                  ...state,
                  InvoiceHeader:NewData
        }

        case GET_PendingInvoice_LIST_Register:
            return {
                ...state,
                    PendingInvoiceList:action.payload.map(x=>({
                    invoiceId:x.id,
                    custumerName:x.custumerName,
                    date: moment(x.date).format("YYYY-MM-DD"),
                    number:zeroPad(x.code,7),
                    value:x.total    
                })) 
            }
        case Reset_Invoice_ROW_MOVEMENTS_TABLE:
            return {
                ...state,
                InvoiceHeader:[]
            }

        case DELETE_INVOICE_DETAIL_iNVOICE_REGISTER_POS:
                const productId  = action.payload;
                return {
                    ...state,
                    InvoiceHeader: state.InvoiceHeader.filter(x => x.productId !== productId)
                }
        case ADD_PROCESS_INVOICE_ID:
            debugger
            return{
                ...state,
                ProcessInvoiceIds:[...state.ProcessInvoiceIds,action.payload],
               
            }
        case ADD_PROCESS_INVOICE_ID_FOR_SALES:
            return{
                ...state,
                ProcessInvoiceIdsForSales:[...state.ProcessInvoiceIds]
            }
        case DELETE_PROCESS_INVOICE_ID:
            return{
                ...state,
                ProcessInvoiceIds:[]
            }
        case DELETE_PROCESS_INVOICE_FOR_SALES_ID :
            return{
                ...state,
                ProcessInvoiceIdsForSales:[]
            }
            
        default:
          return state
    }

}

export default Invoice;