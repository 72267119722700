import {DISABLE_HEADER_INCOME_RECEIPT,SET_PENDING_MOVEMENT_INCOME_RECEIPT,SET_VALUE_TO_DISTRIBUTE_INCOME_RECEIPT,UPDATE_TABLE_DATA_INCOME_RECEIPT,UPDATE_VALUE_TO_DISTRIBUTE_INCOME_RECEIPT,RESET_INCOME_RECEIPT,PENDING_BALANCE_INCOME_RECEIPT,SET_RETAINED_PERCENT_AND_STATUS_INCOME_RECEIPT,Reset_RETAINED_PERCENT_AND_STATUS_INCOME_RECEIPT} from '../../../../constants/ActionTypes'
import moment from 'moment'
import {zeroPad,currencyFormat} from '../../../../util/DaFrameword'
const InitialState={
    DisabledHeader:false,
    PendingMovement:[],
    DistributeValue:0,
    UltimateDistributeValue:0,
    PendingBalance:0,
    AutomaticRetained:false,
    PercentRetained :undefined,

    AutomaticRetainedITBIS:false,
    PercentRetainedITBIS :undefined,
    AutomaticRetainedISR:false,
    PercentRetainedISR :undefined
}

function IncomeReceipt(state=InitialState, action){

        switch (action.type) {

            case DISABLE_HEADER_INCOME_RECEIPT:
                return {
                    ...state,
                    DisabledHeader:true
                }
            case SET_PENDING_MOVEMENT_INCOME_RECEIPT:
                return {
                    ...state,
                    PendingMovement:action.payload.map(x=>({
                        ...x, 
                        DateDisplay:moment(x.date).format("DD/MM/YYYY"),
                        TypeDisplay:`${x.abbreviationCode}`,
                        DocumentDisplay:zeroPad(x.number,x.length),
                        PendingValueDisplay:currencyFormat(x.pendingDebit),
                        Selected:false,
                        PaidValue:0,
                        RetainedValue:0,
                        Concept:""
                    }))
                }
            case SET_VALUE_TO_DISTRIBUTE_INCOME_RECEIPT:
                return {
                    ...state,
                    DistributeValue:action.payload,
                    UltimateDistributeValue:action.payload,
                }
            case UPDATE_TABLE_DATA_INCOME_RECEIPT:
                const Row=action.payload;
                return {
                    ...state,
                    PendingMovement:state.PendingMovement.map(x=>(x.cxcTransactionHeaderId===Row.cxcTransactionHeaderId)?Row:x)
                }
            case UPDATE_VALUE_TO_DISTRIBUTE_INCOME_RECEIPT:
                const PaidValue=state.PendingMovement.reduce((prev,curr)=> prev+(curr.PaidValue),0);
                //console.log(PaidValue,state.UltimateDistributeValue,Math.round(PaidValue,4));
                //Antes hacia un redondeo al restar PaidValue
                return {
                    ...state,
                    DistributeValue:(state.UltimateDistributeValue- PaidValue)
                }
            
            case PENDING_BALANCE_INCOME_RECEIPT:
                return {
                    ...state,
                    PendingBalance:action.payload
                }
            case RESET_INCOME_RECEIPT:
            return  {
                ...InitialState,
                AutomaticRetained:state.AutomaticRetained,
                PercentRetained :state.PercentRetained
            }        
            case SET_RETAINED_PERCENT_AND_STATUS_INCOME_RECEIPT:
                //const q= action.payload
                return {
                    ...state,
                    AutomaticRetainedITBIS: action.payload.AutomaticRetainedITBIS,
                    PercentRetainedITBIS  : action.payload.RetencionPercentITBIS===undefined?0:action.payload.RetencionPercentITBIS,
                    AutomaticRetainedISR: action.payload.AutomaticRetainedISR,
                    PercentRetainedISR  : action.payload.RetencionPercentISR===undefined?0:action.payload.RetencionPercentISR
    
                }

            case Reset_RETAINED_PERCENT_AND_STATUS_INCOME_RECEIPT:
                   return {
                    ...state,
                    AutomaticRetainedITBIS:false,
                    PercentRetainedITBIS :undefined,
                    AutomaticRetainedISR:false,
                    PercentRetainedISR :undefined
    
                }
        
            default:
                return state;      
      }
}

export default IncomeReceipt;
